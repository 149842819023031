import { Controller } from "stimulus"
import { enter, leave } from "./util/transitions"

export default class extends Controller {
  static targets = ["body"]

  connect() {
    this.open = false
  }

  handleGlobalClick(e) {
    if (this.open) {
      this.toggleDropdown()
    }
  }

  handleButtonClick(e) {
    this.toggleDropdown()

    // Stop event propagation so handleGlobalClick doesn't kick in
    e.stopPropagation()
  }

  toggleDropdown() {
    if (this.open) {
      leave(this.bodyTarget).then(() => {
        this.open = false
      })
    } else {
      enter(this.bodyTarget).then(() => {
        this.open = true
      })
    }
  }
}
