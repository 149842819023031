import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cell", "cellDrag", "hiddenField"]

  connect() {
    this.serialize()

    this.sourceElement = null;
    this.cellDragTargets.forEach((cell) => {
      cell.addEventListener('dragstart', this.dragstart.bind(this));
    });
    this.cellTargets.forEach((cell) => {
      cell.addEventListener('dragover', this.dragover.bind(this));
      cell.addEventListener('dragleave', this.dragleave.bind(this));
      cell.addEventListener('drop', this.drop.bind(this));
    });
  }

  serialize(e) {
    // serialize data
    const cells = this.cellTargets.map(cell => {
      return {
        position: cell.dataset.position,
        value: cell.querySelector('input[name="value"]').value,
        explanation: cell.querySelector('input[name="explanation"]').value,
        correct: cell.querySelector('input[name="correct"]').checked,
      }
    })
    this.hiddenFieldTarget.value = JSON.stringify(cells)

    // update cell visuals
    this.cellTargets.forEach(cell => {
      if (cell.querySelector('input[name="correct"]').checked) {
        cell.classList.add('bg-green-100')
        cell.classList.remove('bg-red-100')
      } else {
        cell.classList.add('bg-red-100')
        cell.classList.remove('bg-green-100')
      }
    });
  }

  dragstart(event) {
    this.sourceElement = event.target;
  }

  dragover(event) {
    event.preventDefault();

    let sourceCell = this.sourceElement.closest("[data-quadrivore-cells-target='cell']");
    let targetCell = event.target.closest("[data-quadrivore-cells-target='cell']");

    let sourcePosition = sourceCell.dataset.position;
    let targetPosition = targetCell.dataset.position;

    if (sourcePosition == targetPosition) {
      return;
    }

    targetCell.classList.add('opacity-70');
  }

  dragleave(event) {
    let targetCell = event.target.closest("[data-quadrivore-cells-target='cell']");
    targetCell.classList.remove('opacity-70');
  }

  drop(event) {
    let sourcePosition = this.sourceElement.closest("[data-quadrivore-cells-target='cell']").dataset.position;
    let targetPosition = event.target.closest("[data-quadrivore-cells-target='cell']").dataset.position;

    if (sourcePosition == targetPosition) {
      return;
    }

    event.preventDefault();
    this.dragleave(event);

    this.swapElements(event);
    this.swapPositions(event);
  }

  swapElements(event) {
    let sourceCell = this.sourceElement.closest("[data-quadrivore-cells-target='cell']");
    let targetCell = event.target.closest("[data-quadrivore-cells-target='cell']");

    let sourceCellParent = sourceCell.parentElement;
    let targetCellParent = targetCell.parentElement;

    sourceCellParent.appendChild(targetCell);
    targetCellParent.appendChild(sourceCell);
  }

  swapPositions(event) {
    let sourceCell = this.sourceElement.closest("[data-quadrivore-cells-target='cell']");
    let targetCell = event.target.closest("[data-quadrivore-cells-target='cell']");

    let sourcePositionDisplay = sourceCell.querySelector('[data-target="position-display"]');
    let targetPositionDisplay = targetCell.querySelector('[data-target="position-display"]');

    let sourcePosition = sourceCell.dataset.position;
    let targetPosition = targetCell.dataset.position;

    // update data-position on cell
    sourceCell.dataset.position = targetPosition;
    targetCell.dataset.position = sourcePosition;

    // update position display in draggable element
    sourcePositionDisplay.textContent = targetPosition;
    targetPositionDisplay.textContent = sourcePosition;

    this.serialize();
  }
}
