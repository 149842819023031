import { Controller } from "stimulus"

export default class extends Controller {

  get modalController() {
    var modalElem = document.querySelector('[data-controller="modal"]')
    return this.application.getControllerForElementAndIdentifier(modalElem, "modal")
  }

  connect() {
    this.enabled = false

    document.addEventListener('turbo:submit-end', this.handleTurboSubmit)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleTurboSubmit)
  }

  onClick(e) {
    this.enabled = true
  }

  handleTurboSubmit = (e) => {
    if (this.enabled && e.detail.success) {
      this.modalController.close()
    }
  }
}