import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'copied']
  static values = {
    toStrip: Array
  }

  copy() {
    let text = this.sourceTarget.textContent
    const tempInput = document.createElement('input')
    if (this.hasToStripValue) {
      this.toStripValue.forEach((strip) => {
        text = text.replace(strip, '')
      })
    }
    tempInput.value = text
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
    this.showCopied()
  }

  showCopied() {
    this.copiedTarget.classList.remove('hidden')
    setTimeout(() => {
      this.copiedTarget.classList.add('hidden')
    }, 2000)
  }
}
