import { Controller } from "stimulus"

function arraysContainSame(a, b) {
  let duppedA = a.slice();
  let duppedB = b.slice();
  console.log(duppedA, duppedB)

  if (duppedA.length !== duppedB.length) return false;
  duppedA = duppedA.sort();
  duppedB = duppedB.sort();
  for (let i = 0; i < duppedA.length; i++) {
    if (duppedA[i] !== duppedB[i]) return false;
  }
  return true;
}

export default class extends Controller {
  static targets = ["body"]

  connect() {
    this.form = this.element

    this.replaceQuestionInCategoryCheckboxes = this.element.querySelectorAll(
      'input[name$="[replace_with_new_question]"][value="1"]'
    );
    this.replaceQuestionIdInputs = this.element.querySelectorAll(
      'input[name$="[replace_with_question_id]"]'
    )
    this.positionInputs = this.element.querySelectorAll(
      'input[name$="[position]"]'
    );

    // iterate through all category selects and add originalCategory data attribute
    this.categorySelects = this.element.querySelectorAll(
      'select[name$="[category]"]'
    );
    this.categorySelects.forEach((select) => {
      select.dataset.originalCategory = select.value;
    });

    this.form.addEventListener("submit", this.checkSubmission.bind(this));
  }

  checkSubmission(event) {
    if (this.checkValidPositions(event)) return;
    if (this.confirmReplaceNewCategoryQuestion(event)) return;
    if (this.confirmReplaceNewQuestionId(event)) return;
  }

  checkValidPositions(event) {
    let positions = [];
    this.positionInputs.forEach((input) => {
      positions.push(parseInt(input.value, 10));
    })

    if (!arraysContainSame(positions, [1,2,3,4,5])) {
      alert(`Positions invalid: ${positions}`);
      event.preventDefault();
      event.stopPropagation();
      return true
    }

    return false
  }

  confirmReplaceNewCategoryQuestion(event) {
    let checkedReplaceQuestionInCategoryCheckboxes = [];
    this.replaceQuestionInCategoryCheckboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkedReplaceQuestionInCategoryCheckboxes.push(checkbox);
      }
    });

    // if any, double check w/ user via JS alert that replacements are valid
    if (checkedReplaceQuestionInCategoryCheckboxes.length > 0) {
      let confirmations = []
      checkedReplaceQuestionInCategoryCheckboxes.forEach((checkbox) => {
        const questionFields = checkbox.closest(".nested-fields");
        const questionCategory = questionFields.querySelector('select[name$="[category]').value;
        const originalCategory = questionFields.querySelector('select[name$="[category]').dataset.originalCategory;
        const questionAnswer = questionFields.querySelector('input[name$="[answer]').value;

        if (questionCategory === originalCategory) {
          confirmations.push(`\t ${questionCategory} : ${questionAnswer}`);
        } else {
          confirmations.push(`\t ${questionCategory} : ${questionAnswer}\n - - this was (${originalCategory}), that'll be swapped in`);
        }
      })

      let confirmationMessage = "Are you sure you want to replace the following questions?\n\n";
      confirmationMessage += confirmations.join("\n");

      if (!confirm(confirmationMessage)) {
        event.preventDefault();
        event.stopPropagation();
        return
      }
    }
  }

  confirmReplaceNewQuestionId(event) {
    let presentReplaceQuestionIdInputs = [];
    this.replaceQuestionIdInputs.forEach((input) => {
      if (input.value) {
        presentReplaceQuestionIdInputs.push(input);
      }
    });

    // if any, double check w/ user via JS alert that replacements are valid
    if (presentReplaceQuestionIdInputs.length > 0) {
      let confirmations = []
      presentReplaceQuestionIdInputs.forEach((input) => {
        const questionFields = input.closest(".nested-fields");
        const questionCategory = questionFields.querySelector('select[name$="[category]').value;
        const questionAnswer = questionFields.querySelector('input[name$="[answer]').value;

        confirmations.push(`\t ${questionCategory} : ${questionAnswer}`);
      })

      let confirmationMessage = "Are you sure you want to replace the following questions?\n\n";
      confirmationMessage += confirmations.join("\n");

      if (!confirm(confirmationMessage)) {
        event.preventDefault();
        event.stopPropagation();
        return
      }
    }
  }
}
