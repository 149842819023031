import { Controller } from 'stimulus'

export default class extends Controller {
  disable(event) {
    const element = event.currentTarget
    element.setAttribute('data-original-text', element.innerText)
    element.innerHTML = element.getAttribute('data-disable-with')
    element.disabled = true

    if (element.form) {
      element.form.requestSubmit()
    }
  }
}
