import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  connect() {
    this.isExpanded = false
  }

  onRoundRowClick(e) {
    if (this.isExpanded == false) {
      const request = new FetchRequest("GET", e.currentTarget.dataset.roundUrl)
      request.perform()

    } else {
      const roundId = e.currentTarget.dataset.roundId
      const roundRowExtra = document.querySelector(`#search_round_row_${roundId}_extra`)
      roundRowExtra.innerHTML = ''
    }

    this.isExpanded = !this.isExpanded
  }

}