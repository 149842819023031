import { Controller } from "stimulus"

export default class extends Controller {
  toggleCategory(e) {
    const input = e.target.closest("select")
    if (input.value === "") {
      this.reloadPage("category")
    } else {
      this.reloadPage("category", input.value)
    }
  }

  toggleUsed(e) {
    const input = e.target.closest("label").querySelector("input")
    if (input.checked) {
      this.reloadPage("filter", input.name)
    } else {
      this.reloadPage("filter")
    }
  }

  reloadPage(key, value) {
    const currentParams = new URLSearchParams(window.location.search)

    if (value) {
      currentParams.set(key, value)
    } else {
      currentParams.delete(key)
    }

    window.location.href = `?${currentParams.toString()}`
  }
}
