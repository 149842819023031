import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    content: String,
    contentClassList: Array,
  }

  connect() {
    this.tooltipElem = null
    this.tooltipClickHandler = null
    this.defaultContentClassList = ["top-0", "left-0", "p-2", "text-black", "bg-white", "rounded", "shadow-md", "overflow-y-scroll", "max-w-3xl", "min-w-[240px]"]

    if (this.contentValue == '') {
      console.error("Tooltip controller initialized w/out `data-tooltip-content-value` attribute set.")
    } else {
      this.initFunctionality()
    }
  }

  disconnect() {
    if (this.tooltipClickHandler) {
      this.tooltipElem.removeEventListener('click', this.tooltipClickHandler)
    }
  }

  initFunctionality() {
    this.appendAfterElement()
    this.element.addEventListener('mouseenter', (e) => this.tooltipElem.classList.remove('hidden'))
    this.element.addEventListener('mouseleave', (e) => this.tooltipElem.classList.add('hidden'))
  }

  appendAfterElement() {
    this.element.classList.add("relative")
    this.tooltipElem = document.createElement('div')
    const classList = this.hasContentClassListValue ? this.contentClassListValue : this.defaultContentClassList
    this.tooltipElem.classList.add(...classList)
    this.tooltipElem.classList.add("hidden", "absolute", "z-50")
    this.tooltipClickHandler = this.tooltipElem.addEventListener('click', this.onTooltipClick)

    var parser = new DOMParser()
    var doc = parser.parseFromString(this.contentValue, 'text/html')
    this.tooltipElem.innerHTML = doc.body.innerText
    this.element.append(this.tooltipElem)
  }

  onTooltipClick(e) {
    // to prevent click event from bubbling up to parent element and expanding/collapsing the round's questions & answers
    e.stopPropagation()
  }

}