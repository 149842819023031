import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["details"]

  connect() {
    this.detailsOpen = false
  }

  toggle(e) {
    e.preventDefault()
    this.detailsOpen = !this.detailsOpen

    if (this.detailsOpen) {
      this.detailsTarget.classList.add("block")
      this.detailsTarget.classList.remove("hidden")
    } else {
      this.detailsTarget.classList.remove("block")
      this.detailsTarget.classList.add("hidden")
    }
  }
}
