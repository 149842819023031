import { Controller } from "stimulus"
import { enter, leave } from "./util/transitions"

export default class extends Controller {
  static targets = ["wrapper", "body"]
  static values = {
    skipSubmitClose: Boolean
  }

  connect() {
    enter(this.wrapperTarget)
    enter(this.bodyTarget)

    if (!this.skipSubmitCloseValue) {
      document.addEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  disconnect() {
    if (!this.skipSubmitCloseValue) {
      document.removeEventListener('turbo:submit-end', this.handleSubmit)
    }
  }

  close(e) {
    if (e) { e.preventDefault() }

    leave(this.wrapperTarget)
    leave(this.bodyTarget).then(() => {
      // Remove the modal element after the fade out so it doesn't blanket the screen
      this.element.remove()
    })

    // Remove src reference from parent frame element
    // Without this, turbo won't re-open the modal on subsequent clicks
    this.element.closest("turbo-frame").src = undefined
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }

  closeOnEscape(e) {
    if (e.key === "Escape") {
      this.close()
    }
  }
}
