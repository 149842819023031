import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["AutoOpenLink"]
  static values = {
    delay: Number
  }

  connect() {
    this.delay = this.delayValue || 1000

    setTimeout(() => {
      this.AutoOpenLinkTarget.click()
    }, this.delay)
  }
}