import { Controller } from "stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static values = { url: String }

  static targets = ["results"]

  static debounces = [{
    name: "submit",
    wait: 250
  }]

  connect() {
    useDebounce(this)

    this.submissionInput = this.element.querySelector("input")

    const questionFields = this.element.closest(".nested-fields")
    if (questionFields) {
      this.answerInput = questionFields.querySelector("input[name*='answer']")
      this.alternatesInput = questionFields.querySelector("textarea[name*='alternates']")
      this.incorrectsInput = questionFields.querySelector("textarea[name*='incorrects']")
    } else {
      // on the single question edit page, the form is not nested
      const form = this.element.closest("form")
      this.answerInput = form.querySelector("input[name*='answer']")
      this.alternatesInput = form.querySelector("textarea[name*='alternates']")
      this.incorrectsInput = form.querySelector("textarea[name*='incorrects']")
    }

    // listen to changes in submissionInput, answerInput, and alternatesInput
    // if submissionInput has anything in it, submit
    this.submissionInput.addEventListener("keyup", this.checkSubmit.bind(this))
    this.answerInput.addEventListener("keyup", this.checkSubmit.bind(this))
    this.alternatesInput.addEventListener("keyup", this.checkSubmit.bind(this))
    this.incorrectsInput.addEventListener("keyup", this.checkSubmit.bind(this))
  }

  checkSubmit() {
    if (this.submissionInput.value) {
      this.submit()
    } else {
      this.resultsTarget.innerHTML = ""
    }
  }

  async submit() {
    if (this.nothingChanged()) {
      return
    }

    fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        submission: this.submissionInput.value,
        answer: this.answerInput.value,
        alternates: this.alternatesInput.value,
        incorrects: this.incorrectsInput.value
      }),
    }).then((response) => response.json()).then((data) => {
      if (data.correct) {
        this.resultsTarget.innerHTML = `<span class="text-green-600">${data.reason}</span>`
      } else {
        if (data.reason)  {
          this.resultsTarget.innerHTML = `<span class="text-red-600">${data.reason}</span>`
        } else {
          this.resultsTarget.innerHTML = `<span class="text-red-600">no match</span>`
        }
      }
    })
  }

  nothingChanged() {
    const newFormString = this.formToString()
    if (this.formString === newFormString) {
      return true
    }

    this.formString = newFormString
    return false
  }

  formToString() {
    // collect data from 3 inputs
    const submission = this.submissionInput.value
    const answer = this.answerInput.value
    const alternates = this.alternatesInput.value
    const incorrects = this.incorrectsInput.value

    return `${submission}${answer}${alternates}${incorrects}`
  }
}
