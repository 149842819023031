import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["RoundsOnlyInput", "QuestionTypeInputContainer", "RoundSubTypeInputContainer"]

  onRoundsOnlyInputChange(e) {
    const roundsOnly = e.target.value == "rounds"

    if (roundsOnly) {
      this.QuestionTypeInputContainerTarget.classList.add("hidden")
      this.RoundSubTypeInputContainerTarget.classList.remove("hidden")
    } else {
      this.QuestionTypeInputContainerTarget.classList.remove("hidden")
      this.RoundSubTypeInputContainerTarget.classList.add("hidden")
    }
  }

  onAnswersOnlyInputChange(e) {
    const answersOnly = e.target.value == "answers"

    if (answersOnly) {
      this.QuestionTypeInputContainerTarget.classList.remove("hidden")
      this.RoundSubTypeInputContainerTarget.classList.add("hidden")
    }
  }

}