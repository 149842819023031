import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ["ScrollContainer", "Table", "Entries", "Pagination"]
  static values = {
    maxResults: Number
  }

  onScroll(_) {
    const nextPageElem = this.PaginationTarget.querySelector("a[rel='next']")
    if (nextPageElem == null) { return }

    const url = nextPageElem.href
    const tableScrollY = this.ScrollContainerTarget.scrollTop
    const scrollContainerHeight = this.ScrollContainerTarget.offsetHeight
    const tableHeight = this.TableTarget.offsetHeight
    const preBottomHeightOffset = 400

    if (tableScrollY >= tableHeight - scrollContainerHeight - preBottomHeightOffset) {
      this.loadMore(url)
    }
  }

  async loadMore(url) {
    if (this.loading) { return }
    this.loading = true

    const request = new FetchRequest("GET", `${url}&max_results=${this.maxResultsValue}`, { responseKind: "json" })
    const response = await request.perform()

    if (response.ok) {
      const responseJson = await response.json

      this.EntriesTarget.insertAdjacentHTML("beforeend", responseJson.entries)
      this.PaginationTarget.innerHTML = responseJson.pagination
      this.loading = false
    } else {
      alert("There was an error loading more results.")
    }
  }

}