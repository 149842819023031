import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submitWrapper", "submitButton"]

  handleCheck() {
    // if any checkboxes are checked, show the bulk delete button
    // otherwise, hide it
    if (this.checkboxTargets.some((checkbox) => checkbox.checked)) {
      this.submitWrapperTarget.classList.remove("hidden")
    } else {
      this.submitWrapperTarget.classList.add("hidden")
    }
  }

  submitDelete(e) {
    e.preventDefault()

    const checkedCount = this.checkboxTargets.filter((checkbox) => checkbox.checked).length

    if (confirm(`Are you sure you want to bulk delete ${checkedCount} questions?`) == true) {
      // (I wrote the following comment, GH Copilot did the rest 😬)
      // assemble list of ids from checked checkboxTargets
      const ids = this.checkboxTargets
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.value)

      // // submit the form with the list of ids
      const form = document.createElement("form")
      form.method = "POST"
      form.action = this.submitButtonTarget.href
      form.innerHTML = `
        <input type="hidden" name="_method" value="delete" />
        <input type="hidden" name="ids" value="${ids.join(",")}" />
        <input type="hidden" name="authenticity_token" value="${document.querySelector("meta[name='csrf-token']").content}" />
      `
      document.body.appendChild(form)
      form.requestSubmit()
    }
  }
}
