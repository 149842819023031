import { Controller } from "stimulus"
import { enter, leave } from "./util/transitions"

export default class extends Controller {
  static targets = ["rounds", "chevron"]

  connect() {
    this.visible = false
  }

  toggle() {
    this.visible = !this.visible

    if (this.visible) {
      this.chevronTarget.classList.add('rotate-180')
      enter(this.roundsTarget)
    } else {
      this.chevronTarget.classList.remove('rotate-180')
      leave(this.roundsTarget)
    }
  }
}
