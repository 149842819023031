import { Controller } from "stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {

  onTurboNavigate(e) {
    e.preventDefault()

    let url = e.currentTarget.dataset.url

    // if cmd-click, open url in new tab
    if (e.metaKey || e.ctrlKey) {
      window.open(url, "_blank")
      return
    }

    // lean on Turbo to:
    // - make the request
    // - update the browser history
    // - render the turbo-stream response to replace the page content
    Turbo.visit(url, { action: "advance" })
  }
}
