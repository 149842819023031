import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'button']

  onButtonClick(e) {
    e.preventDefault()

    this.contentTarget.classList.toggle('hidden')
    this.buttonTarget.classList.toggle('hidden')
  }
}
