import { Controller } from "stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static values = {
    formSelection: String
  }

  static debounces = [{
    name: "submit",
    wait: 500
  }]

  connect() {
    useDebounce(this)

    this.autosaveIndicator = document.querySelector("#autosave-indicator")

    if (this.formSelectionValue == "up") {
      this.form = this.element.closest("form")
    } else {
      this.form = this.element.querySelector("form")
    }

    // Cover all possible user actions, the form will only submit
    // if something has changed, and be debounced at that
    this.form.addEventListener("keyup", this.submit.bind(this))
    this.form.addEventListener("change", this.submit.bind(this))
    this.form.addEventListener("click", this.submit.bind(this))

    this.formString = this.formToString()
  }

  async submit() {
    if (this.nothingChanged()) {
      return
    }

    this.showSavingIcon()
    const success = await this.autosubmitForm()
    this.hideSavingIcon(success)
  }

  showSavingIcon() {
    this.autosaveIndicator.classList.remove("invisible")
    this.autosaveIndicator.style.opacity = 1
    this.autosaveIndicator.textContent = "Saving..."
  }

  hideSavingIcon(success) {
    // Set text for 1.5 second, then fade out
    if (success) {
      this.autosaveIndicator.textContent = "Saved"
    } else {
      this.autosaveIndicator.textContent = "Error"
    }

    setTimeout(this.fadeIconToInvisible.bind(this), 1500)
  }

  async autosubmitForm() {
    // add element to form to signify that it was autosubmitted
    const newInput = document.createElement("input")
    newInput.type = "hidden"
    newInput.name = "autosubmitted"
    newInput.value = "true"
    this.form.appendChild(newInput)

    // submit form
    // have to use ajax so i can pass the response to Turbo
    const response = await fetch(this.form.action, {
      method: this.form.method,
      body: new FormData(this.form),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml"
      }
    })

    // pass response body to turbo
    const html = await response.text()

    if (response.ok) {
      Turbo.renderStreamMessage(html)
    } else {
      console.error("Error saving form:")
      console.error(html);
    }

    // remove element from form
    this.form.removeChild(newInput)

    return response.ok
  }

  fadeIconToInvisible() {
    // drop opacity slowly from 1-0 over 1/2 a second
    // then apply invisible class
    this.autosaveIndicator.style.opacity = 1
    const fadeEffect = setInterval(() => {
      if (this.autosaveIndicator.style.opacity > 0) {
        this.autosaveIndicator.style.opacity -= 0.1
      } else {
        clearInterval(fadeEffect)
        this.autosaveIndicator.classList.add("invisible")
      }
    }, 50)
  }

  nothingChanged() {
    const newFormString = this.formToString()
    if (this.formString === newFormString) {
      return true
    }

    this.formString = newFormString
    return false
  }

  formToString() {
    const formData = new FormData(this.form)
    const params = new URLSearchParams(formData)

    // Not sure why, but this value changes after initial load. This method is only
    // used to check on user input though, so just remove it
    params.delete("authenticity_token")

    return params.toString()
  }
}
